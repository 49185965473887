import React from 'react'

import { Section } from './styles'

import { useBreakpoint } from 'src/hooks/window/useBreakpoint'

import CorretoraTablet from '../../assets/corretora.png'
import CorretoraMobile from '../../assets/corretora-mobile.png'

export const Corretora = () => {
  const breakpoint = useBreakpoint()

  const image = breakpoint.up('md') ? CorretoraTablet : CorretoraMobile

  return (
    <Section>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-4 col-lg-5 col-xl-4 order-md-last offset-md-2 offset-lg-1 mb-4 mb-md-0'>
            <img
              src={image}
              alt='Uma mesa com notebook, calculadora e papéis de relatórios.'
              className='d-block mx-auto'
            />
          </div>

          <div className='col-12 col-md-6 col-xl-7'>
            <h2 className='font-sora fw-600 fs-20 lh-25 fs-md-22 lh-md-28 fs-lg-30 lh-lg-38 fs-xl-40 lh-xl-50 text-grayscale--500 mb-3 mr-n2 mr-xl-n3'>
              Investimentos Empresariais sem custos na melhor corretora digital
              do Brasil
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-0 mr-n2 mr-md-n3 mr-lg-n1 mr-xl-n4'>
              Invista com facilidade e autonomia e, se precisar de auxílio nas
              decisões do seu negócio, pensamos seu planejamento junto com você.
              <br />
              <br />
              Na conta Entreprise, as soluções de alocação de carteira são
              exclusivas, de acordo com o perfil da sua empresa e preparadas por
              profissionais qualificados.
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}
