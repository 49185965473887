import React from 'react'

import { Section } from './styles'
import { useBreakpoint } from 'src/hooks/window/useBreakpoint'

export const Advisor = () => {
  const breakpoint = useBreakpoint()

  const descColor = breakpoint.up('md') ? 'text-grayscale--500' : 'text-grayscale--400'

  return (
    <Section className='d-xl-flex align-items-xl-center'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-md-6 offset-lg-7'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-3 mr-lg-n2 mr-xl-0'>
              Conte com um Advisor Enterprise exclusivo
            </h2>
            <p className={`fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 ${descColor} mb-0 mr-n2`}>
              Garanta que um de nossos especialistas esteja sempre disponível
              para te ajudar em decisões de negócios, recomendar produtos Inter,
              auxiliar em dúvidas sobre o Super App e realmente simplificar a
              sua rotina.
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}
