import React from 'react'

import { Section } from './styles'

import WhatsappImage from '../../assets/whatsapp.svg'

export const Whatsapp = () => {
  return (
    <Section>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-5 col-lg-6 col-xl-5 offset-xl-1 d-flex justify-content-center justify-content-xl-end order-xl-last mb-4 mb-md-0'>
            <img src={WhatsappImage} alt='Tela de contato no Whatsapp' className='d-bloc w-100' />
          </div>

          <div className='col-12 col-md-6 offset-md-1 col-lg-6 offset-lg-0 order-xl-first'>
            <h2 className='font-sora fw-600 fs-20 lh-25 fs-md-22 lh-md-28 fs-lg-30 lh-lg-38 fs-xl-40 lh-xl-50 text-grayscale--500 mb-3 mr-n2'>
              Atendimento exclusivo no WhatsApp
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-0 mr-n2 mr-xl-n4'>
              Com a Conta PJ Enterprise a sua empresa conta com atendimento
              exclusivo via WhatsApp, para realmente simplificar a comunicação
              com seu Advisor.
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}
