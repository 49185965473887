import React from 'react'
import useUtms from 'src/hooks/useUtms'
import pageContext from './pageContext.json'
import BaseLayout from 'src/layouts/BaseLayout'

import {
  Hero,
  Conheca,
  Advisor,
  Whatsapp,
  Corretora,
  Empresa,
  Duvida,
} from './sections/_index'

function PJEnterprise () {
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }
  return (
    <BaseLayout handleHeaderOpenAccount={() => openAccountPJFormModal()} pageContext={pageContext}>
      <Hero />
      <Conheca />
      <Advisor />
      <Whatsapp />
      <Corretora />
      <Empresa />
      <Duvida />
    </BaseLayout>
  )
}

export default PJEnterprise
