import React from 'react'

import UserAccount from '@interco/icons/build-v4/orangeds/MD/user-account'
import Chat from '@interco/icons/build-v4/orangeds/MD/chat'
import Investments from '@interco/icons/build-v4/orangeds/MD/investments'
import DigitalAccount from '@interco/icons/build-v4/orangeds/MD/digital-account'
import Pix from '@interco/icons/build-v4/orangeds/MD/pix-outline'
import DepositBoleto from '@interco/icons/build-v4/orangeds/MD/deposit-by-boleto'
import Cashback from '@interco/icons/build-v4/orangeds/MD/cashback'

import { green } from 'src/styles/colors'

const iconProps = {
    width: 32,
    height: 32,
    color: green[500],
}

export const data = [
    {
        icon: <UserAccount {...iconProps} />,
        title: 'Advisor Enterprise',
        description: 'Conte com a ajuda de especialistas na hora de tomar decisões para sua empresa.',
    },
    {
        icon: <Chat {...iconProps} />,
        title: 'Atendimento personalizado ágil',
        description: 'Falar com a gente nunca foi tão fácil! O Inter tem consultores a sua disposição, por WhatsApp, pra te ajudar quando precisar.',
    },
    {
        icon: <Investments {...iconProps} />,
        title: 'Perfis de investimento personalizados',
        description: 'Valorize o dinheiro do seu negócio! Criamos opções de investimentos de acordo com a necessidade da sua empresa.',
    },
    {
        icon: <DigitalAccount {...iconProps} />,
        title: 'Conta digital sem taxas',
        description: 'Conte com um Super App gratuito e com diversos serviços para realmente simplificar o dia a dia de sua empresa.',
    },
    {
        icon: <Cashback {...iconProps} />,
        title: 'Inter Loop',
        description: 'Acumule pontos no Cartão de Crédito e Saldo em Conta da sua empresa e escolha como trocá-los diretamente no Super App.',
    },
    {
        icon: <Pix {...iconProps} />,
        title: 'Pix grátis e ilimitado',
        description: 'Cadastre até 20 chaves Pix para o seu negócio e aproveite o meio de pagamentofavorito dos brasileiros sem pagar taxas.',
    },
    {
        icon: <DepositBoleto {...iconProps} />,
        title: 'Boletos grátis todo mês',
        description: 'Mais praticidade e economia para a rotina: emita boletos sem taxas sempre que precisar e aproveite até 100 liquidações gratuitas mensais.',
    },
]
