import React, { MouseEvent } from 'react'

import { EventCaller } from 'inter-site-components'
import ScrollToComponent from 'scroll-to'

type LikToProps = {
    to: string;
    children: React.ReactNode;
    btncolor?: string;
    sendDatalayerEvent?: Function;
    section?: string;
    sectionName?: string;
    elementName?: string;
}

function LinkTo ({ to, children, btncolor, sendDatalayerEvent, section, sectionName, elementName }: LikToProps) {
  function scrollTo (evt: MouseEvent<HTMLAnchorElement>) {
    evt && evt.preventDefault()
    const topElement = document.getElementById(evt.currentTarget.hash.replace('#', ''))
    if (!topElement) { return }

    const offsetTopElem = topElement.getBoundingClientRect()
    const top = offsetTopElem.top + window.scrollY - 70

    ScrollToComponent(0, top, {
      ease: 'in-out-quad',
      duration: 1100,
    })
  }

  return (
    <EventCaller>
      <a
        href={to}
        onClick={(evt: MouseEvent<HTMLAnchorElement>) => {
          scrollTo(evt)
          sendDatalayerEvent && sendDatalayerEvent({
            section: section,
            section_name: sectionName,
            element_name: elementName,
            element_action: 'click scroll',
            redirect_url: window.location.href + to,
          })
        }}
        className={btncolor}
      >
        {children}
      </a>
    </EventCaller>
  )
}

export default LinkTo
