import React from 'react'

import { Section } from './styles'

import { useBreakpoint } from 'src/hooks/window/useBreakpoint'

import DuvidaImage from '../../assets/duvida.png'

export const Duvida = () => {
  const breakpoint = useBreakpoint()

  const enterpriseLabel = breakpoint.up('md') ? 'Enterprise' : 'ENTERPRISE'
  const accountLabel = breakpoint.up('md') ? 'Conta' : 'CONTA'
  const descLabel = breakpoint.up('xl')
    ? 'Conte com nossos canais oficiais de atendimento para clarear qualquer questão sobre o PJ Enterprise.'
    : 'Conte com a gente para tirar todas as suas dúvidas através dos nossos canais oficiais de atendimento.'

  return (
    <Section>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5 col-xl-4 order-md-last order-lg-first mb-4 mb-md-0'>
            <img
              src={DuvidaImage}
              alt='Uma mão segurando um smartphone com o Super App Inter Empresas em destaque na tela.'
              className='d-block mx-auto w-100'
            />
          </div>

          <div className='col-12 col-md-6 col-lg-6 offset-lg-1 col-xl-5 offset-xl-2'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-3 mr-n2 mr-xl-n3'>
              Ficou alguma dúvida sobre a{' '}
              <span className='fw-400 fw-md-600'>{accountLabel}</span> PJ{' '}
              {enterpriseLabel}?
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400 mb-0 mr-n2'>
              {descLabel}
              <span className='fw-700 d-block mt-4'>
                Para sua comodidade, salve em sua agenda: 0800 940 0007
              </span>
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}
