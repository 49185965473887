import React from 'react'

import { Card, Section } from './styles'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { data } from './_data'
import { green } from 'src/styles/colors'
import { useBreakpoint } from 'src/hooks/window/useBreakpoint'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

export const Conheca = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleLink = () => {
    sendDatalayerEvent({
      section: 'dobra_2',
      section_name: 'Veja os benefícios exclusivos para o seu negócio',
      element_action: 'click button',
      element_name: 'Abrir conta PJ',
      redirect_url:
        'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1215774156.1681312069&_ga=2.138851507.1892852811.1684245636-1215774156.1681312069',
    })
    window.open(
      'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1215774156.1681312069&_ga=2.138851507.1892852811.1684245636-1215774156.1681312069',
      '_blank',
    )
  }
  const breakpoint = useBreakpoint()

  const enterpriseLabel = breakpoint.up('md') ? 'Enterprise' : 'ENTERPRISE'
  const descriptionLabel = breakpoint.up('xl')
    ? 'Gerir sua empresa fica ainda mais fácil com vantagens que só o PJ Enterprise tem.'
    : 'Benefícios exclusivos da Conta PJ Enterprise para sua empresa. Estamos aqui para simplificar a vida do seu negócio com vantagens que só o PJ Enterprise tem.'

  return (
    <Section id='beneficios'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-xl-5 mb-4 mb-md-0'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 text-grayscale--500 mb-3 d-xl-none'>
              Conheça a <span className='fw-400 fw-md-600'>Conta</span> PJ{' '}
              {enterpriseLabel}
            </h2>
            <h2 className='font-sora fw-600 fs-40 lh-50 text-grayscale--500 mb-3 d-none d-xl-block'>
              Veja os benefícios exclusivos para o seu negócio
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--500 mb-0 mb-md-4 pb-lg-2 mr-xl-5'>
              {descriptionLabel}
            </p>
            <button
              className='btn btn-green--500 text-none rounded-3 w-100 mw-100 d-none d-md-block'
              onClick={handleLink}
            >
              Abrir conta PJ
            </button>
          </div>

          <div className='col-12 col-md-6 offset-xl-1 pl-xl-0'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 25 }}
              md={{ items: 1, partialVisibilityGutter: 50 }}
              lg={{ items: 1, partialVisibilityGutter: 170 }}
              xl={{ items: 2, partialVisibilityGutter: 0 }}
              customColor='green-500'
              customDotColor={green[500]}
            >
              {data.map((item: typeof data[0]) => (
                <Card key={item.title}>
                  <div>
                    <div className='mb-3 pb-2'>{item.icon}</div>
                    <p className='font-sora fw-600 fs-16 lh-20 text-grayscale--500 mb-3 custom-height'>
                      {item.title}
                    </p>
                    <p className='fs-14 lh-17 text-grayscale--500 mb-0'>
                      {item.description}
                    </p>
                  </div>
                </Card>
              ))}
            </DefaultCarousel>
            <button
              className='btn btn-green--500 text-none rounded-3 w-100 mw-100 mt-4 d-md-none'
              onClick={handleLink}
            >
              Abrir conta PJ
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}
