import styled from 'styled-components'

import Advisor from '../../assets/advisor.png'
import AdvisorTablet from '../../assets/advisor-tablet.png'
import AdvisorDesktopLG from '../../assets/advisor-desktopLG.png'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  padding-top: 363px;
  padding-bottom: 40px;

  background: url(${Advisor}) #ccd6d3 no-repeat;
  background-size: 100% 339px;

  @media ${device.tablet} {
    padding-top: 89px;
    padding-bottom: 89px;
    background: url(${AdvisorTablet}) #ccd6d3 no-repeat left;
    background-size: auto;
  }

  @media ${device.desktopLG} {
    padding-top: 133px;
    padding-bottom: 133px;
    background: url(${AdvisorDesktopLG}) #ccd6d3 no-repeat left;
  }

  @media ${device.desktopXL} {
    padding-top: 184px;
    padding-bottom: 184px;
    background: url(${Advisor}) #ccd6d3 no-repeat left;
  }

  @media ${device.desktopXXXL} {
    padding-top: 184px;
    padding-bottom: 184px;
    height: 750px;
    background: url(${Advisor}) #ccd6d3 no-repeat left;
    background-size: contain;
  }
`
