import { useEffect, useState } from 'react'
import { Utility } from 'inter-site-components'

import { widths } from 'src/styles/breakpoints'

import useDomReady from './useDomReady'

type WidthOptions = keyof typeof widths;

const DEBOUNCE_VALUE = 100

export const useBreakpoint = () => {
  const domReady = useDomReady()

  const [ current, setCurrent ] = useState<number>(widths.sm)

  useEffect(() => {
    setCurrent(window.innerWidth)

    const updateWidthValues = Utility.debounce(() => {
      setCurrent(window.innerWidth)
    }, DEBOUNCE_VALUE)

    window &&
      window.addEventListener(
        'resize',
        (updateWidthValues as unknown) as EventListenerOrEventListenerObject,
        false,
      )
    return () => {
      window &&
        window.removeEventListener(
          'resize',
          (updateWidthValues as unknown) as EventListenerOrEventListenerObject,
          false,
        )
    }
  }, [])

  const is = (width: WidthOptions): boolean => {
    if (!domReady) return false

    const objKeys = Object.keys(widths)
    const currentKeyIndex = objKeys.findIndex(
      (obj: WidthOptions | string) => obj === width,
    )

    const currentKey = objKeys[currentKeyIndex] as WidthOptions
    const previousKey = objKeys[currentKeyIndex - 1] as WidthOptions
    const nextKey = objKeys[currentKeyIndex + 1] as WidthOptions

    if (previousKey === undefined) return current < widths[nextKey]

    if (nextKey === undefined) return current >= widths[currentKey]

    return current >= widths[currentKey] && current < widths[nextKey]
  }

  const up = (width: WidthOptions): boolean => {
    if (!domReady) return false

    return current >= widths[width]
  }

  const down = (width: WidthOptions): boolean => {
    if (!domReady) return false

    return current < widths[width]
  }

  const between = (initial: WidthOptions, final: WidthOptions): boolean => {
    if (!domReady) return false

    return current >= widths[initial] && current <= widths[final]
  }

  return { is, up, down, between, current }
}
