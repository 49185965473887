import React from 'react'

import { Section } from './styles'
import { useBreakpoint } from 'src/hooks/window/useBreakpoint'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import LinkTo from 'src/components/LinkTo/LinkTo'

export const Hero = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleLink = () => {
    sendDatalayerEvent({
      section: 'dobra_1',
      section_name: 'Conta PJ Enterprise: Pra sua empresa ir além',
      element_action: 'click button',
      element_name: 'Abrir conta PJ',
      redirect_url:
        'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1215774156.1681312069&_ga=2.138851507.1892852811.1684245636-1215774156.1681312069',
    })
    window.open(
      'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1215774156.1681312069&_ga=2.138851507.1892852811.1684245636-1215774156.1681312069',
      '_blank',
    )
  }
  const breakpoint = useBreakpoint()

  const titleColor = breakpoint.up('md') ? 'text-white' : 'text-green--500'
  const sectionColor = breakpoint.up('md')
    ? 'text-white'
    : 'text-grayscale--500'
  const accountLabel = breakpoint.up('md') ? 'CONTA' : 'Conta'
  const descriptioonLabel = breakpoint.up('xl')
    ? 'Simplifique ainda mais a rotina do seu negócio com um Advisor exclusivo no Super App e mais benefícios Inter Empresas.'
    : 'Leve o relacionamento empresarial com Inter para o próximo nível. Tenha um advisor exclusivo sempre que precisar e aproveite todas as vantagens de ser Enterprise!'
  const PJProLabel = breakpoint.up('xl')
    ? 'Conheça a conta PJ Enterprise'
    : 'Conheça a Conta PJ Pro'

  return (
    <Section className='d-flex align-items-center' id='conta-pj-enterprise'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <h1
              className={`fw-400 font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 ${titleColor} mb-3`}
            >
              {accountLabel} <span className='fw-600'>PJ ENTERPRISE:</span>{' '}
              <span className={`fw-600 ${sectionColor} d-block mt-2 mt-md-0`}>
                Pra sua empresa ir além
              </span>
            </h1>
            <p
              className={`fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 ${sectionColor} mb-4 pb-2 pb-xl-3 mr-md-n2`}
            >
              {descriptioonLabel}
            </p>
            <button
              className='btn btn-orange--extra text-none rounded-3 fs-10 lh-12 mb-3 mb-md-0 mb-xl-4 w-100 mw-100'
              onClick={handleLink}
            >
              Abrir Conta PJ
            </button>
            <LinkTo to='#beneficios'>
              <button
                className='btn btn--outline btn--orange text-none rounded-3 fs-10 lh-12 d-md-none d-xl-block w-100 mw-100'
                onClick={() =>
                  sendDatalayerEvent({
                    section: 'dobra_1',
                    section_name:
                      'Conta PJ Enterprise: Pra sua empresa ir além',
                    element_action: 'click scroll',
                    element_name: 'Conheça a conta PJ Enterprise',
                  })
                }
              >
                {PJProLabel}
              </button>
            </LinkTo>
          </div>
        </div>
      </div>
    </Section>
  )
}
