import styled from 'styled-components'

import { grayscale, green, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
    padding-top: 40px;
    padding-bottom: 40px;

    @media ${device.desktopLG} {
        padding-top: 64px;
        padding-bottom: 64px;
    }

    @media ${device.desktopXL} {
        padding-top: 96px;
        padding-bottom: 96px;
    }

    ul {
        padding-top: 0!important;
        margin-top: 0!important;
    }

    button {
        color: ${white};
        background: ${green[500]};
        height: 48px;
        font-family: 'Sora', sans-serif;
    }
`

export const Card = styled.div`
    background: ${white};
    padding: 24px;
    border: 1px solid ${grayscale[200]};
    border-radius: 16px;
    width: 264px;
    height: 276px;
    
    @media ${device.desktopXL} {
        margin-right: 24px;
        width: auto;
    }

    .custom-height {
        height: 40px;
    }
`
