import styled from 'styled-components'

import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

import BannerMD from '../../assets/bg-hero-md.png'
import BannerLG from '../../assets/bg-hero-lg.png'
import BannerXL from '../../assets/bg-hero-xl.png'

export const Section = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  background: ${grayscale[100]};

  @media ${device.tablet} {
    height: 471px;
    background: url(${BannerMD}) no-repeat center;
    background-size: cover;
  }

  @media ${device.desktopLG} {
    background: url(${BannerLG}) no-repeat center;
  }

  @media ${device.desktopXL} {
    height: 815px;
    background: url(${BannerXL}) no-repeat center;
  }

  @media ${device.desktopXXXL} {
    height: 815px;
    background: url(${BannerXL}) no-repeat center;
    background-size: cover;
  }

  button {
    height: 32px;
    font-size: 10px;
    line-height: 12px;

    @media ${device.tablet} {
      height: 48px;
      font-size: 14px;
      line-height: 18px;
      font-family: 'Sora', sans-serif;
    }
  }
`
