import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    background: #ccd6d3;
    padding-top: 96px;
    padding-bottom: 96px;
  }

  img {
    max-width: 200px;
    margin: auto;
    box-shadow: 0px 16px 48px -8px rgba(22, 22, 22, 0.16);

    @media ${device.tablet} {
      margin-left: unset;
      max-width: 216px;
    }

    @media ${device.desktopLG} {
      max-width: 376px;
    }

    @media ${device.desktopXL} {
      max-width: none;
    }
  }
`
