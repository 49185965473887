import React from 'react'

import Investments from '@interco/icons/build-v4/orangeds/MD/investments'
import DigitalAccount from '@interco/icons/build-v4/orangeds/MD/digital-account'

import Wallet from '@interco/icons/build-v4/orangeds/MD/wallet'
import CheckArrow from '@interco/icons/build-v4/orangeds/MD/check-arrows'
import MoneyUp from '@interco/icons/build-v4/orangeds/MD/money-up'

import { green } from 'src/styles/colors'

const iconProps = {
  width: 16,
  height: 16,
  color: green[500],
}

export const data = [
  {
    icon: { sm: <Investments {...iconProps} /> },
    title: 'Carteira de investimentos entre R$ 1 milhão e R$ 5 milhões',
  },
  {
    icon: {
      sm: <DigitalAccount {...iconProps} />,
      md: <Wallet {...iconProps} />,
    },
    title: 'Empréstimo contratado acima de R$100 mil',
  },
  {
    icon: {
      sm: <CheckArrow {...iconProps} />,
      md: <MoneyUp {...iconProps} />,
    },
    title: 'Movimentação média de no mínimo R$200 mil nos últimos três meses',
  },
]
